import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Media } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { Link } from "@ryerson/frontend.navigation";

export type Topics = {
	topic: string;
	link?: string;
};

export type EqualOpportunityContent = {
	title: string;
	description: string;
	imageUrl: string;
	mobileImageUrl: string;
	listTitle: string;
	list: Topics[];
};

export type EqualOpportunityProps = {
	content: EqualOpportunityContent;
};

const Description = styled.div`
	width: calc(100% - 360px);
	display: inline-block;
	margin-bottom: 50px;
	vertical-align: middle;
	padding-right: 200px;
	box-sizing: border-box;
`;

const Box = styled.div`
	height: 480px;
	width: 100%;
	border-radius: 3px;
	${(props: any) => {
		const { theme } = props;
		return css`
			border: 1px solid ${theme.colors.primary.lighterGray};
		`;
	}}
`;

const RootFlexStyle = styled(Flex)`
	padding: 10px;
`;

const FlexStyle = styled(FlexItem)`
	padding: 10px;
`;

const Label = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			border-bottom: 1px solid ${theme.colors.primary.lighterGray};
			color: ${theme.colors.primary.header};
		`;
	}}
`;
const SchoolName = styled(Typography)`
	margin-top: 0;
`;
const DesktopTable = styled.div`
	display: inline-block;
	vertical-align: top;
	height: 480px;
	margin-right: 40px;
	width: calc(100% - 465px);
`;

const DesktopImage = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 100%;
	width: 425px;
	height: 480px;
`;

const DesktopSpacer = styled.div`
	display: block;
	width: 100%;
	height: 100px;
`;

const HeaderDiv = styled.div`
	width: 100%;
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.lighterGray};
			padding: 20px;
			@media (max-width: ${theme.breakpoints.lg}) {
				padding: 7px 20px;
			}
		`;
	}}
`;

const Table = (props: any) => {
	const { theme } = useTheme();
	const g = theme.colors.primary.lighterGray;
	return (
		<Box theme={theme}>
			<HeaderDiv theme={theme}>
				<Typography size="sm" weight="bold" color={theme.colors.primary.header}>
					{props.title}
				</Typography>
			</HeaderDiv>
			<RootFlexStyle direction="column" theme={theme}>
				{props.data.map((school: any, index: number) => (
					<FlexStyle key={index} grow={1} theme={theme}>
						<Label
							css={css`
								border-bottom: ${index === props.data.length - 1
									? "none"
									: "border-bottom: 1px solid " + g};
							`}
							theme={theme}
							key={index}
						>
							{school.link ? (
								<Link to={school.link} gatsby={false} target="_blank">
									<SchoolName
										variant="p"
										size="md"
										color={theme.colors.primary.label}
									>
										{school.topic}
									</SchoolName>
								</Link>
							) : (
								<SchoolName
									variant="p"
									size="md"
									color={theme.colors.primary.label}
								>
									{school.topic}
								</SchoolName>
							)}
						</Label>
					</FlexStyle>
				))}
			</RootFlexStyle>
		</Box>
	);
};

const MobileTable = (props: any) => {
	const { theme } = useTheme();
	const g = theme.colors.primary.lighterGray;
	return (
		<div>
			<HeaderDiv theme={theme}>
				<Typography size="sm" weight="bold" color={theme.colors.primary.header}>
					{props.title}
				</Typography>
			</HeaderDiv>
			<RootFlexStyle
				css={css`
					padding-left: 0px !important;
					padding-right: 0px !important;
				`}
				direction="column"
				theme={theme}
			>
				{props.data.map((school: any, index: number) => (
					<FlexStyle key={index} grow={1} theme={theme}>
						<Label
							css={css`
								border-bottom: ${index === props.data.length - 1
									? "none"
									: "border-bottom: 1px solid " + g};
							`}
							theme={theme}
							key={index}
						>
							{school.link ? (
								<Link to={school.link} gatsby={false} target="_blank">
									<SchoolName
										variant="p"
										size="md"
										color={theme.colors.primary.label}
									>
										{school.topic}
									</SchoolName>
								</Link>
							) : (
								<SchoolName
									variant="p"
									size="md"
									color={theme.colors.primary.label}
								>
									{school.topic}
								</SchoolName>
							)}
						</Label>
					</FlexStyle>
				))}
			</RootFlexStyle>
		</div>
	);
};

const EqualOpportunity: React.FC<EqualOpportunityProps> = ({ content }) => {
	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="40px">
					<Typography
						variant="h2"
						css={css`
							margin-bottom: 25px;
						`}
					>
						{content.title}
					</Typography>
					<Description>
						<Typography size="md">{content.description}</Typography>
					</Description>

					<DesktopTable>
						<Table title={content.listTitle} data={content.list} />
					</DesktopTable>

					<DesktopImage>
						<img
							src={content.imageUrl}
							style={{
								maxWidth: "100%",
								maxHeight: "100%",
							}}
						/>
					</DesktopImage>
					<DesktopSpacer />
				</ContentSection>
			</Media>

			<Media lessThan="lg">
				<Typography
					size="md"
					variant="div"
					css={css`
						margin-top: 28px;
						margin-bottom: 20px;
					`}
				>
					{content.description}
				</Typography>
				<Flex direction="column">
					<FlexItem>
						<MobileTable title={content.listTitle} data={content.list} />
					</FlexItem>
					<FlexItem>
						<img
							src={content.mobileImageUrl}
							css={css`
								width: 100%;
								height: auto;
								margin-top: 15px;
								margin-bottom: 60px;
							`}
						/>
					</FlexItem>
				</Flex>
			</Media>
		</>
	);
};

export default EqualOpportunity;
